<template>
  <div class="main-heading">
    <h2>{{ "Match Config" }}</h2>
    <Button
      text="Save"
      type="success"
      :onClick="() => submit()"
      :loading="isLoading"
    />
  </div>

  <div
    class="pool-container"
    v-for="(pool, index) in config.arena_groups"
    v-bind:key="index"
  >
    <h3>{{ "Pool " + (index + 1).toString() }}</h3>
    <div style="display: flex; flex-direction: row">
      <div class="form-field">
        <h5>Arena Index Begin (Inclusive)</h5>
        <input class="input" v-model="pool.arena_begin" type="number" />
      </div>
      <div class="form-field">
        <h5>Arena Index End (Inclusive)</h5>
        <input class="input" v-model="pool.arena_end" type="number" />
      </div>
    </div>
    <div class="form-field">
      <h5>Modifiers</h5>
      <multiselect
        v-model="pool.modifier_pool"
        :options="modifiers.map((x) => x.id)"
        :multiple="true"
        :searchable="false"
        placeholder="Select Modifiers"
      >
        <template v-slot:tag="slotProps">
          <span
            ><span>{{
              modifiers.find((x) => x.id === slotProps.option).localizables
                .name["en"] + ", "
            }}</span></span
          >
        </template>
        <template v-slot:option="slotProps">
          <span class="option__desc"
            ><span class="option__title">{{
              modifiers.find((x) => x.id === slotProps.option).localizables
                .name["en"]
            }}</span></span
          >
        </template>
      </multiselect>
    </div>
    <hr />
  </div>

  <div class="pool-container">
    <div class="form-field">
      <h3>Modifiers Enabled on Coop</h3>
      <input type="checkbox" v-model="config.enabled_on_coop" />
    </div>
    <div class="form-field">
      <h3>Coop Modifiers</h3>
      <multiselect
        v-model="config.coop_modifier_pool"
        :options="modifiers.map((x) => x.id)"
        :multiple="true"
        :searchable="false"
        placeholder="Select Modifiers"
      >
        <template v-slot:tag="slotProps">
          <span
            ><span>{{
              modifiers.find((x) => x.id === slotProps.option).localizables
                .name["en"] + ", "
            }}</span></span
          >
        </template>
        <template v-slot:option="slotProps">
          <span class="option__desc"
            ><span class="option__title">{{
              modifiers.find((x) => x.id === slotProps.option).localizables
                .name["en"]
            }}</span></span
          >
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref, onBeforeMount, onUpdated, watch } from "vue";
import { useStore } from "vuex";
import Button from "../../../components/common/Button.vue";
import modifierConstants from "@/constants/modifierConstants";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "MatchConfig",
  methods: {},
  components: {
    Button,
  },
  setup() {
    const store = useStore();

    const config = ref(null);
    const modifiers = ref([]);
    const isLoading = ref(false);

    const usedPages = ["modifiers"];

    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );

    onUpdated(() => {
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["serverConfig/getMatchmakingConfig"],
      (value) => {
        config.value = JSON.parse(JSON.stringify(value));
      },
      { immediate: true }
    );

    watch(
      () => store.getters["gameModifiers/getGameModifiers"],
      (value) => {
        modifiers.value = [...value];
      },
      { immediate: true }
    );

    const submit = () => {
      isLoading.value = true;
      store
        .dispatch("serverConfig/updateMatchmakingConfig", config.value)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      config,
      modifiers,
      modifierConstants,
      isLoading,
      submit,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.main-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0 25px;
}

.input {
  width: 300px;
  height: 35px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.form-field {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pool-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 15px 40px;
  text-align: left;
}
</style>