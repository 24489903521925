<template>
  <div class="main-heading">
    <h2>{{ "Modifiers" }}</h2>
    <Button text="Add" type="success" :onClick="() => $router.push({ name: 'Modifier' })" />
  </div>

  <div class="modifier-table">
    <div class="row row-header">
      <div
        v-for="column in columns"
        v-bind:key="column"
        class="cell cell-header"
        :style="{
          width: 100 / columns.length + '%',
          maxWidth: 100 / columns.length + '%',
        }"
      >
        {{ $t(column) }}
      </div>
    </div>
    <div
      class="row"
      v-for="modifier in modifiers"
      v-bind:key="modifier.id"
      @click="
        () => $router.push({ name: 'Modifier', query: { id: modifier.id } })
      "
    >
      <div
        class="cell"
        :style="{
          width: 100 / columns.length + '%',
          maxWidth: 100 / columns.length + '%',
        }"
      >
        {{ modifier.id }}
      </div>
      <div
        class="cell"
        :style="{
          width: 100 / columns.length + '%',
          maxWidth: 100 / columns.length + '%',
        }"
      >
        {{ modifier.localizables.name['en'] }}
      </div>
      <div
        class="cell"
        :style="{
          width: 100 / columns.length + '%',
          maxWidth: 100 / columns.length + '%',
        }"
      >
        {{ modifierConstants.MODIFIER_TYPES.find(x => x.value === modifier.modifier_type).text }}
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, ref, onBeforeMount, onUpdated, watch } from "vue";
import { useStore } from "vuex";
import Button from "../../../components/common/Button.vue";
import dispatchMap from "@/constants/dispatchMap";
import modifierConstants from "@/constants/modifierConstants";

export default {
  name: "Modifiers",
  methods: {},
  components: {
    Button,
  },
  setup() {
    const store = useStore();

    const modifiers = ref([]);

    const usedPages = ["modifiers"];

    onBeforeMount(() =>
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => store.dispatch(dispatchStr));
      })
    );

    onUpdated(() => {
      store.dispatch("loader/loadingStatus", false);
    });
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => store.getters["gameModifiers/getGameModifiers"],
      (value) => {
        modifiers.value = [...value];
      },
      { immediate: true }
    );

    return {
      modifiers,
      modifierConstants,
      columns: ["Id", "Name", "Type"],
    };
  },
};
</script>


<style scoped>
.main-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0 25px;
}

.modifier-table {
  margin: 20px 15px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.row {
  flex-grow: 1;
  width: 100%;
  display: inline-flex;
  max-height: 80px;
  overflow: hidden;
  align-items: center;
}

.row:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 10px;
  min-height: 60px;
  display: flex;
  justify-content: left;
  align-items: center;
}
</style>